// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-commands-tsx": () => import("./../../../src/pages/commands.tsx" /* webpackChunkName: "component---src-pages-commands-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-music-tsx": () => import("./../../../src/pages/music.tsx" /* webpackChunkName: "component---src-pages-music-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-videos-tsx": () => import("./../../../src/pages/videos.tsx" /* webpackChunkName: "component---src-pages-videos-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-release-tsx": () => import("./../../../src/templates/release.tsx" /* webpackChunkName: "component---src-templates-release-tsx" */)
}

